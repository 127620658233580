import { Component, OnInit, ElementRef } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { Video } from '../../video';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DragulaService } from 'ng2-dragula';
import { Image } from '../../image';
import { UploadImageComponent } from '../upload-image/upload-image.component';

@Component({
  selector: 'app-fragment',
  templateUrl: './fragment.component.html',
  styleUrls: ['./fragment.component.css']
})
export class FragmentComponent implements OnInit {
  private imagecollection: AngularFirestoreCollection<any>;
  images: any[];
  private loaded = false;

  constructor(private auth: AuthService, private afs: AngularFirestore, private elementRef: ElementRef, private dialog: MatDialog, private drag: DragulaService) {

    this.imagecollection = afs.collection<any>('fragment', ref => ref.orderBy('orderid'));
    this.imagecollection.valueChanges().subscribe(x => {
      this.images = x;
      console.log(x)
      this.loaded= true;
    });
   }
  
  
  ngOnInit() {
    
  }
  ngAfterViewInit(){
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#2f3133';
 }

 addImage() {
  this.dialog.closeAll();
  const config = new MatDialogConfig();
  config.autoFocus = true;
  config.disableClose = true;
  this.dialog.open(UploadImageComponent, config);
}
update() {
  for (let index = 0; index < this.images.length; index++) {
    this.images[index].orderid = index;
    console.log(this.images[index].orderid)
    this.afs.doc<Video>('fragment/' + this.images[index].id).update(this.images[index]);
  }
  alert('updated!');
}
delete(image: Video) {
  if (confirm('Are you sure you want to delete this image?')) {
    this.afs.doc<Video>('fragment/' + image.id).delete();
  }
}
}
